import React from "react"
import styled from "styled-components"
import {
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  Button,
} from "@material-ui/core"
import {
  PersonRounded,
  PhoneEnabledRounded,
  EmailRounded,
  HouseRounded,
} from "@material-ui/icons"
import { breakpoints } from "./variables"
import { useState } from "react"
import * as EmailValidator from "email-validator"
import clsx from "clsx"
import axios from "axios"

const FormW = styled.div`
  width: 47%;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`
const SFormControl = styled(FormControl)`
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }
  & .MuiInput-underline:after {
    border-color: ${props => props.theme.palette.sage.main};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.palette.sage.main};
  }
  & .Mui-error:after {
    border-color: ${props => props.theme.palette.error.main};
    color: ${props => props.theme.palette.error.main};
  }
  & .Mui-error.Mui-focused {
    color: ${props => props.theme.palette.error.main};
  }
`
const SInputLabel = styled(InputLabel)`
  font-size: 1.2rem;
`
const SInput = styled(Input)`
  font-size: 1.2rem;
`
const SubmitW = styled.div`
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
`
const SuccessCover = styled.div`
  position: absolute;
  left: -100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  background-color: ${props => props.theme.palette.datamap.main};
  color: ${props => props.theme.palette.sage.light};
  transition: left 1s;
  &.active {
    left: 0;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`
const ErrorCover = styled.div`
  position: absolute;
  left: -100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  background-color: ${props => props.theme.palette.datamap.main};
  color: ${props => props.theme.palette.error.light};
  transition: left 1s;
  &.active {
    left: 0;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`
const SButton = styled(Button)`
  border-radius: 2rem;
  font-size: 1.2rem;
  padding: 0.5rem 1.4rem;
  background-color: ${props => props.theme.palette.datamap.main};
  color: ${props => props.theme.palette.primary.main};
  transition: background-color 1s;
  &:hover {
    background-color: ${props => props.theme.palette.sage.main};
  }
`

const FormSubmitter = ({ referer, className }) => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [nameError, setNameError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [companyError, setCompanyError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const formChangeHandler = event => {
    const name = event.target.name
    const value = event.target.value

    switch (name) {
      case "name":
        setName(value)
        setNameError(false)
        break
      case "phone":
        setPhone(value)
        setPhoneError(false)
        break
      case "email":
        setEmail(value)
        setEmailError(false)
        break
      case "company":
        setCompany(value)
        setCompanyError(false)
        break
      default:
        break
    }
  }

  const formSubmitHandler = () => {
    if (!new RegExp(/[a-zA-Z]{3,}/g).test(name)) {
      setNameError(true)
      return
    }
    if (new RegExp(/([a-zA-Z]|^$)/g).test(phone)) {
      setPhoneError(true)
      return
    }
    if (!EmailValidator.validate(email)) {
      setEmailError(true)
      return
    }
    if (!new RegExp(/[a-zA-Z]{3,}/g).test(company)) {
      setCompanyError(true)
      return
    }
    axios({
      method: "POST",
      url: "https://webapi.datamap.tools",
      headers: {
        "content-type": "application/json",
      },
      data: {
        referer: referer,
        name: name,
        phone: phone,
        email: email,
        company: company,
      },
    })
      .then(() => {
        setSuccess(true)
        setTimeout(() => {
          setName("")
          setPhone("")
          setEmail("")
          setCompany("")
          setSuccess(false)
        }, 2000)
      })
      .catch(() => {
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 2000)
      })
  }

  return (
    <FormW>
      <SFormControl fullWidth>
        <SInputLabel htmlFor="form-name">Name</SInputLabel>
        <SInput
          id="form-name"
          type={"text"}
          endAdornment={
            <InputAdornment position="end">
              <PersonRounded />
            </InputAdornment>
          }
          name="name"
          onChange={formChangeHandler}
          value={name}
          error={nameError ? true : false}
        />
      </SFormControl>
      <SFormControl fullWidth>
        <SInputLabel htmlFor="form-phone">Phone</SInputLabel>
        <SInput
          id="form-phone"
          type={"text"}
          endAdornment={
            <InputAdornment position="end">
              <PhoneEnabledRounded />
            </InputAdornment>
          }
          name="phone"
          onChange={formChangeHandler}
          value={phone}
          error={phoneError ? true : false}
        />
      </SFormControl>
      <SFormControl fullWidth>
        <SInputLabel htmlFor="form-email">Email</SInputLabel>
        <SInput
          id="form-email"
          type={"text"}
          endAdornment={
            <InputAdornment position="end">
              <EmailRounded />
            </InputAdornment>
          }
          name="email"
          onChange={formChangeHandler}
          value={email}
          error={emailError ? true : false}
        />
      </SFormControl>
      <SFormControl fullWidth>
        <SInputLabel htmlFor="form-companyName">Company name</SInputLabel>
        <SInput
          id="form-companyName"
          type={"text"}
          endAdornment={
            <InputAdornment position="end">
              <HouseRounded />
            </InputAdornment>
          }
          name="company"
          onChange={formChangeHandler}
          value={company}
          error={companyError ? true : false}
        />
      </SFormControl>
      <SubmitW>
        <SButton onClick={formSubmitHandler}>Submit</SButton>
        <SuccessCover
          className={clsx("successCover", className, { active: success })}
        >
          Thank You!
        </SuccessCover>
        <ErrorCover
          className={clsx("errorCover", className, { active: error })}
        >
          Please try again.
        </ErrorCover>
      </SubmitW>
    </FormW>
  )
}

export default FormSubmitter
